@import '../../../shared/styles/variable';

.main-content-view {
  padding: 20px 20px 60px;

  @media screen and (min-width: @screen-md) {
    padding: 24px 24px 60px;
  }
}

.main-content-view-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: auto;
}

@primary-color: #b2842d;